import React, { useState, useEffect } from "react";
import classes from "./NavBar.module.css";
import { NavLink } from "react-router-dom";
import { Logo } from "../../Assets";
import NavItem from "./NavItem/NavItem";
import Dropdown from "./Dropdown/Dropdown";

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isTop = window.scrollY < 60;
      setScrolled(!isTop);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navItems = {
    "/location": "LOCATION",
    "/photos": "PHOTOS",
    "/booking": "AVAILABILITY/BOOKING",
    "/faq": "FAQ/Rules",
  };

  return (
    <div>
      <ul
        className={
          scrolled ? `${classes.Navbar} ${classes.scrolled}` : classes.Navbar
        }
      >
        <div onMouseEnter={() => setDropdownOpen(false)}>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? `${classes.active} homeLink` : "homeLink"
            }
          >
            <img src={Logo} alt="Home" />
          </NavLink>
        </div>
        <div onMouseEnter={() => setDropdownOpen(true)}>
          <NavItem link="/packages" scrolled={scrolled}>
            PACKAGES <i className="fas fa-chevron-down"></i>
          </NavItem>
        </div>
        {Object.entries(navItems).map(([link, label], index) => (
          <div onMouseEnter={() => setDropdownOpen(false)} key={index}>
            <NavItem key={link} link={link} scrolled={scrolled}>
              {label}
            </NavItem>
          </div>
        ))}
      </ul>
      {dropdownOpen && (
        <Dropdown scrolled={scrolled} offHover={() => setDropdownOpen(false)} />
      )}
    </div>
  );
};

export default Navbar;
