import { Link } from "react-router-dom";
import classes from "./Dropdown.module.css";

interface DropdownProps {
  scrolled: boolean; // Indicates if the navbar is scrolled
  offHover: () => void; // Function to close the dropdown
}

const Dropdown: React.FC<DropdownProps> = ({ scrolled, offHover }) => {
  const topMargin = scrolled ? "50px" : "60px";
  const leftMargin = scrolled ? "150px" : "160px";
  const backgroundColor = scrolled ? "#2e2e2e" : "rgba(0, 0, 0, 0.6)";
  return (
    <div
      className={classes.div}
      style={{ marginTop: topMargin, marginLeft: leftMargin, backgroundColor }}
      onMouseLeave={() => {
        offHover();
      }}
    >
      <Link to="/packages" className={classes.Link}>
        Packages
      </Link>
      <Link to="/vendors" className={classes.Link}>
        Preferred Vendors
      </Link>
    </div>
  );
};

export default Dropdown;
