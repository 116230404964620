import React from "react";
import GoogleMapReact from "google-map-react";
import "./map.css";

const Map = () => {
  const location = {
    lat: 48.01821,
    lng: -121.93457,
    address: "22305 N Carpenter Rd, Snohomish, WA, 98290",
  };
  const renderMarkers = (map: any, maps: any) => {
    let marker = new maps.Marker({
      position: { lat: location.lat, lng: location.lng },
      map,
      title: "Hello World!",
    });
    return marker;
  };
  return (
    <div className="map">
      <div className="google-map">
        <GoogleMapReact
          defaultCenter={location}
          defaultZoom={13}
          bootstrapURLKeys={{ key: "AIzaSyCJ3e9DtZ0-D8C63acirUqk9oJw7vm0N6s" }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        />
      </div>
    </div>
  );
};

export default Map;
