import React, { Component } from "react";
import MenuIcon from "@mui/icons-material/Dehaze";
import classes from "./Toolbar.module.css";
import { Link } from "react-router-dom";
import NavItem from "../NavBar/NavItem/NavItem";
import { Logo } from "../../Assets";

class Toolbar extends Component {
  state = {
    scrolled: false,
    showToolbar: false,
  };

  componentDidMount() {
    window.addEventListener("scroll", () => {
      const isTop = window.scrollY < 60;
      if (!isTop && !this.state.scrolled) {
        this.setState({ scrolled: true });
      } else if (isTop && this.state.scrolled) {
        this.setState({ scrolled: false });
      }
    });
    window.addEventListener("click", (click) => {
      if (this.state.showToolbar && click.clientY > 68) {
        this.setState({ showToolbar: false });
      }
    });
  }

  toggleToolbar = () => {
    this.setState((prevState) => {
      //@ts-ignore
      return { showToolbar: !prevState.showToolbar };
    });
  };

  render() {
    return (
      <div>
        <ul
          className={
            this.state.scrolled
              ? `${classes.Toolbar} ${classes.scrolled}`
              : classes.Toolbar
          }
        >
          <MenuIcon
            fontSize="large"
            style={{ padding: "16px 0 0 15px" }}
            onClick={this.toggleToolbar}
          />
          <Link to="/" onClick={() => this.setState({ showToolbar: false })}>
            <img
              src={Logo}
              alt="Pretty Bird Weddings and Events"
              className={classes.img}
            />
          </Link>
        </ul>
        {this.state.showToolbar && (
          <div
            className={
              this.state.scrolled ? classes.clickedScrolled : classes.clicked
            }
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ width: "150px" }}>
                <NavItem link="/" scrolled={this.state.scrolled}>
                  HOME
                </NavItem>
                <NavItem link="/packages" scrolled={this.state.scrolled}>
                  PACKAGES
                </NavItem>
                <NavItem link="/vendors" scrolled={this.state.scrolled}>
                  PREFERRED VENDORS
                </NavItem>
                <NavItem link="/location" scrolled={this.state.scrolled}>
                  LOCATION
                </NavItem>
                <NavItem link="/photos" scrolled={this.state.scrolled}>
                  PHOTOS
                </NavItem>
              </div>
              <div style={{ width: "150px" }}>
                <NavItem link="/booking" scrolled={this.state.scrolled}>
                  AVAILABILITY & BOOKING
                </NavItem>
                <NavItem link="/faq" scrolled={this.state.scrolled}>
                  FAQs/Rules
                </NavItem>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Toolbar;
