import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import NavBar from "./Components/NavBar/NavBar";
import classes from "./App.module.css";
import Location from "./Pages/Location/Location";
import Vendors from "./Pages/Vendors/Vendors";
import Photos from "./Pages/Photos/Photos";
import Packages from "./Pages/Packages/Packages";
import Contact from "./Pages/Contact/Contact";
import Toolbar from "./Components/Toolbar/Toolbar";
import FAQsRules from "./Pages/FAQ/FAQ";

const App = () => {
  return (
    <div className={classes.app}>
      <BrowserRouter>
        <NavBar />
        <Toolbar />
        <Routes>
          <Route path={"/"} element={<Home />} />
          <Route path={"/packages"} element={<Packages />} />
          <Route path={"/vendors"} element={<Vendors />} />
          <Route path={"/booking"} element={<Contact />} />
          <Route path={"/location"} element={<Location />} />
          <Route path={"/photos"} element={<Photos />} />
          <Route path={"/faq"} element={<FAQsRules />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
