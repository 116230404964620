import React from "react";
import classes from "./Photos.module.css";
import headerClasses from "../Home/Home.module.css";
import "react-slideshow-image/dist/styles.css";
import { Slide } from "react-slideshow-image";
import {
  amphiteater,
  Contact0,
  Home1,
  Venue11,
  Venue12,
  Venue14,
  Venue15,
  Venue16,
  Venue17,
  Venue18,
  Venue19,
  Venue20,
  Venue21,
  Venue22,
  Venue23,
  Venue24,
  Venue27,
  Venue28,
  Venue30,
  Venue32,
  Venue33,
  Venue34,
  Venue36,
  Venue4,
  Venue5,
  Venue7,
  Venue8,
  VenueUpdated0,
  VenueUpdated2,
  VenueUpdated3,
  VenueUpdated4,
  VenueUpdated5,
  VenueUpdated6,
  VenueUpdated7,
  Wedding0,
  Wedding14,
  Wedding15,
  Wedding16,
  Wedding23,
  Wedding17,
  Wedding19,
  Wedding2,
  Wedding22,
  Wedding24,
  Wedding3,
  Wedding4,
  Wedding6,
  Wedding8,
  chickenCoop,
  amphitheaterDrone,
  foggyAmphitheater,
  Barn1,
  Barn2,
  Barn3,
  Bedroom1,
  Bedroom2,
  Bedroom3,
  Bedroom4,
  Bedroom5,
  barnCake,
  barnAisle,
  Wedding5,
  outside,
} from "../../Assets";

const Photos = () => {
  const houseImages = [
    VenueUpdated0,
    amphitheaterDrone,
    VenueUpdated2,
    VenueUpdated6,
    VenueUpdated4,
    VenueUpdated5,
    VenueUpdated7,
    VenueUpdated3,
    Venue4,
    Venue5,
    Venue7,
    Venue12,
    Venue17,
    Venue8,
    Venue14,
    Venue36,
    Home1,
    chickenCoop,
    foggyAmphitheater,
  ];
  const houseInteriorImages = [
    Venue15,
    Venue16,
    Venue18,
    Venue19,
    Bedroom1,
    Bedroom2,
    Bedroom3,
    Bedroom4,
    Bedroom5,
    Venue20,
    Venue21,
    Venue22,
    Venue23,
    Venue24,
    Venue27,
    Venue28,
    Venue30,
    Contact0,
  ];
  const barnImages = [Venue32, Venue11, Venue33, Venue34, Barn1, Barn2, Barn3];
  const weddingImages = [
    amphiteater,
    barnCake,
    Wedding23,
    barnAisle,
    Wedding2,
    Wedding22,
    Wedding0,
    outside,
    Wedding19,
    Wedding3,
    Wedding5,
    Wedding4,
    Wedding6,
    Wedding8,
    Wedding14,
    Wedding15,
    Wedding16,
    Wedding17,
    Wedding24,
  ];
  const slideProps = { transitionDuration: 300, autoplay: false };

  return (
    <div>
      <div className={classes.headerBackdrop}>
        <div className={headerClasses.header}>
          <h1>Photos</h1>
        </div>
      </div>
      <h2 className={classes.slideshowLabel}>House/Amphitheater</h2>
      <div className={classes.slideshow}>
        <Slide {...slideProps}>
          {houseImages.map((image, index) => {
            return (
              <div key={index} className={classes.imgWrapper}>
                <img src={image} alt="" />
              </div>
            );
          })}
        </Slide>
      </div>
      <h2 className={classes.slideshowLabel}>House Interior</h2>
      <div className={classes.slideshow}>
        <Slide {...slideProps}>
          {houseInteriorImages.map((image, index) => {
            return (
              <div key={index} className={classes.imgWrapper}>
                <img src={image} alt="" />
              </div>
            );
          })}
        </Slide>
      </div>
      <h2 className={classes.slideshowLabel}>Barn</h2>
      <div className={classes.slideshow}>
        <Slide {...slideProps}>
          {barnImages.map((image, index) => {
            return (
              <div key={index} className={classes.imgWrapper}>
                <img src={image} alt="" />
              </div>
            );
          })}
        </Slide>
      </div>
      <h2 className={classes.slideshowLabel}>Wedding Pictures</h2>
      <div className={classes.slideshow} style={{ paddingBottom: "50px" }}>
        <Slide {...slideProps}>
          {weddingImages.map((image, index) => {
            return (
              <div key={index} className={classes.imgWrapper}>
                <img src={image} alt="" />
              </div>
            );
          })}
        </Slide>
      </div>
    </div>
  );
};

export default Photos;
