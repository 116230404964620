import React from "react";
import Map from "./Map/Map";
import classes from "./Location.module.css";
import headerClasses from "../Home/Home.module.css";

const Location = () => {
  return (
    <div className={classes.location}>
      <div className={classes.headerBackdrop}>
        <div className={headerClasses.header}>
          <h1>LOCATION & DIRECTIONS</h1>
        </div>
        <h2 className={classes.address}>
          22305 North Carpenter Rd, Snohomish, WA, 98290
        </h2>{" "}
        <div className={classes.mapsLink}>
          <a
            href="https://goo.gl/maps/zBSspNMXWpVffKz7A"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            View in Google Maps
          </a>
        </div>
      </div>

      <h2 className={[classes.listHeader, classes.extraMargin].join(" ")}>
        From I-5
      </h2>
      <ol>
        <li>Head northwest on I-5 N</li>
        <li>Take exit 194 for US-2 E toward Snohomish/Wenatchee (0.3 mi)</li>
        <li>Continue onto US-2 E/U.S. Rte 2 E (1.9 mi)</li>
        <li>Take the exit on the left toward 20th SE (3.1 mi mi)</li>
        <li>20th St SE turns right and becomes Williams Rd (0.5 mi)</li>
        <li>Turn right onto Machias Cutoff (0.2 mi)</li>
        <li>Continue onto S Machias Rd (1.1 mi)</li>
        <li>Turn right onto OK Mill Rd (3.7 mi)</li>
        <li>Continue onto S Carpenter Rd (3.9 mi)</li>
        <li>Turn left into your destination</li>
      </ol>
      <h2 className={classes.listHeader}>From State Highway 9</h2>
      <ol>
        <li>Head north on WA-9 N</li>
        <li>Turn right onto Bunk Foss Rd (1.2 mi)</li>
        <li>Turn left onto Old Machias Rd (0.6 mi)</li>
        <li>Turn left onto S Machias Rd (0.7 mi)</li>
        <li>Turn right onto Dubuque Rd (5 mi)</li>
        <li>Turn left onto Creswell Rd (1.4 mi)</li>
        <li>Turn right onto S Carpenter Rd (3.9 mi)</li>
        <li>Turn left into your destination</li>
      </ol>
      <Map />
    </div>
  );
};

export default Location;
