import {
  blueHeron,
  peacock,
  silkieChicken,
  whiteFlowers,
  whiteHummingbird,
  whiteOsprey,
  quail,
  foodTable,
  swan,
  finch,
} from "../../Assets";

const ConvertedPackages = () => (
  <div style={{ overflow: "hidden", marginLeft: "-37px" }}>
    <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
    <p style={{ textAlign: "left" }}>
      <span />
    </p>
    <p />
    <p style={{ textIndent: "0pt", textAlign: "left" }}>
      <br />
    </p>
    <h1
      style={{
        textAlign: "center",
        marginLeft: "37px",
      }}
    >
      Pretty Bird Weddings and Events Packages
    </h1>
    <p style={{ textIndent: "0pt", textAlign: "left" }}>
      <br />
    </p>
    <p style={{ textIndent: "0pt", textAlign: "left" }}>
      <span />
    </p>
    <ul id="l1">
      <div
        style={{
          textAlign: "center",
          width: "100vw",
          position: "relative",
        }}
      >
        <img
          src={whiteHummingbird}
          style={{ width: "300px", mixBlendMode: "screen" }}
        />
      </div>
      <p />
      <p
        className="s1"
        style={{
          textAlign: "center",
          fontSize: "22px",
        }}
      >
        Hummingbird Package
      </p>
      <div
        style={{
          display: "flex",
          width: "min(500px, 80vw)",
          position: "relative",
          flexDirection: "column",
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <table
          style={{
            borderCollapse: "collapse",
          }}
          cellSpacing={0}
        >
          <tbody>
            <tr style={{ height: "24pt" }}>
              <td
                style={{
                  width: "133pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "136pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Fridays and Weekends
                </p>
              </td>
              <td
                style={{
                  width: "85pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Weekdays
                </p>
              </td>
            </tr>
            <tr style={{ height: "23pt" }}>
              <td
                style={{
                  width: "133pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Event + overnight stay in 2024
                </p>
              </td>
              <td
                style={{
                  width: "136pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  $4,000
                </p>
              </td>
              <td
                style={{
                  width: "85pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  $1,400
                </p>
              </td>
            </tr>
            <tr style={{ height: "23pt" }}>
              <td
                style={{
                  width: "133pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Event + overnight stay in 2025
                </p>
              </td>
              <td
                style={{
                  width: "136pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  $5,000
                </p>
              </td>
              <td
                style={{
                  width: "85pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  $1,900
                </p>
              </td>
            </tr>
            <tr style={{ height: "23pt" }}>
              <td
                style={{
                  width: "133pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Event + overnight stay in 2026
                </p>
              </td>
              <td
                style={{
                  width: "136pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  $5,500
                </p>
              </td>
              <td
                style={{
                  width: "85pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  $2,200
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        style={{
          display: "flex",
          width: "min(500px, 80vw)",
          position: "relative",
          flexDirection: "column",
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "11pt",
              paddingLeft: "41pt",
              textIndent: "-18pt",
              lineHeight: "113%",
              textAlign: "left",
            }}
          >
            Pricing includes tables and chairs for up to 130 guests, use of the
            lodge house (sleeps 16), barn, amphitheater, and the grounds. Horses
            are available for visiting and photography but not for riding
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "-18pt",
              lineHeight: "14pt",
              textAlign: "left",
            }}
          >
            Basic lighting and decor in the barn to create a warm and inviting
            ambiance
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            Use of the bridal suite and groom’s room for getting ready
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            Full kitchen allowing for meal preparation and catering in lodge
            house
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            Ample parking for guests
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "41pt",
              textIndent: "-18pt",
              lineHeight: "113%",
              textAlign: "justify",
            }}
          >
            Packages for catering, equipment rental, photography, setup and
            cleanup are booked separately. Tables and chairs will be available
            in the barn in a standard arrangement
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "-18pt",
              lineHeight: "112%",
              textAlign: "justify",
            }}
          >
            Clients are permitted to bring in their own food, bar service, and
            photographers if desired
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <span />
          </p>
          <p />
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingLeft: "41pt",
              textIndent: "-18pt",
              lineHeight: "112%",
              textAlign: "justify",
            }}
          >
            We also require event insurance for all events. Insurance is
            typically $100 - $400 depending on event details
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
        </li>
        <hr style={{ width: "540px", marginLeft: "-25px" }} />
      </div>
      <div
        style={{
          textAlign: "center",
          width: "100vw",
          position: "relative",
        }}
      >
        <img
          src={whiteOsprey}
          style={{ width: "300px", mixBlendMode: "screen" }}
        />
      </div>
      <p
        className="s1"
        style={{
          textAlign: "center",
          fontSize: "22px",
        }}
      >
        Osprey Package
      </p>
      <div
        style={{
          display: "flex",
          width: "min(500px, 80vw)",
          position: "relative",
          flexDirection: "column",
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <table style={{ borderCollapse: "collapse" }} cellSpacing={0}>
          <tbody>
            <tr style={{ height: "24pt" }}>
              <td
                style={{
                  width: "136pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "137pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Fridays and Weekends
                </p>
              </td>
              <td
                style={{
                  width: "83pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Weekdays
                </p>
              </td>
            </tr>
            <tr style={{ height: "24pt" }}>
              <td
                style={{
                  width: "136pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Event + overnight stay in 2024
                </p>
              </td>
              <td
                style={{
                  width: "137pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  $4,500
                </p>
              </td>
              <td
                style={{
                  width: "83pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  $2,000
                </p>
              </td>
            </tr>
            <tr style={{ height: "24pt" }}>
              <td
                style={{
                  width: "136pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Event + overnight stay in 2025
                </p>
              </td>
              <td
                style={{
                  width: "137pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  $6,000
                </p>
              </td>
              <td
                style={{
                  width: "83pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  $2,900
                </p>
              </td>
            </tr>
            <tr style={{ height: "24pt" }}>
              <td
                style={{
                  width: "136pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Event + overnight stay in 2026
                </p>
              </td>
              <td
                style={{
                  width: "137pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  $6,600
                </p>
              </td>
              <td
                style={{
                  width: "83pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  $3,200
                </p>
              </td>
            </tr>
          </tbody>
        </table>{" "}
      </div>
      <div
        style={{
          display: "flex",
          width: "min(500px, 80vw)",
          position: "relative",
          flexDirection: "column",
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "3pt",
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            Includes everything in the Hummingbird Package
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            Tablecloths, plates, silverware, and place settings for up to 130
            guests
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            Glass vase centerpieces for tables (ﬂowers not included)
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <span />
          </p>
        </li>
        <hr style={{ width: "540px", marginLeft: "-25px" }} />
      </div>

      <p />
      <div
        style={{
          textAlign: "center",
          width: "100vw",
          position: "relative",
        }}
      >
        <img
          src={blueHeron}
          style={{ width: "300px", mixBlendMode: "screen" }}
        />
      </div>
      <p
        className="s1"
        style={{
          textAlign: "center",
          fontSize: "22px",
        }}
      >
        Blue Heron Package
      </p>
      <div
        style={{
          display: "flex",
          width: "min(500px, 80vw)",
          position: "relative",
          flexDirection: "column",
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <table style={{ borderCollapse: "collapse" }} cellSpacing={0}>
          <tbody>
            <tr style={{ height: "24pt" }}>
              <td
                style={{
                  width: "132pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "137pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Fridays and Weekends
                </p>
              </td>
              <td
                style={{
                  width: "81pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Weekdays
                </p>
              </td>
            </tr>
            <tr style={{ height: "23pt" }}>
              <td
                style={{
                  width: "132pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Event + overnight stay in 2024
                </p>
              </td>
              <td
                style={{
                  width: "137pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  $5,000
                </p>
              </td>
              <td
                style={{
                  width: "81pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  $2,200
                </p>
              </td>
            </tr>
            <tr style={{ height: "23pt" }}>
              <td
                style={{
                  width: "132pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Event + overnight stay in 2025
                </p>
              </td>
              <td
                style={{
                  width: "137pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  $6,500
                </p>
              </td>
              <td
                style={{
                  width: "81pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  $3,000
                </p>
              </td>
            </tr>
            <tr style={{ height: "23pt" }}>
              <td
                style={{
                  width: "132pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Event + overnight stay in 2026
                </p>
              </td>
              <td
                style={{
                  width: "137pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  $7,100
                </p>
              </td>
              <td
                style={{
                  width: "81pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  $3,300
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        style={{
          display: "flex",
          width: "min(500px, 80vw)",
          position: "relative",
          flexDirection: "column",
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "11pt",
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            Includes everything in the Hummingbird Package
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "41pt",
              textIndent: "-18pt",
              lineHeight: "112%",
              textAlign: "left",
            }}
          >
            Additional tables and chairs, setup and takedown included (up to 220
            total guest capacity)
          </p>
        </li>
        <hr style={{ width: "540px", marginLeft: "-25px" }} />
      </div>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <span />
      </p>
      <p />
      <div
        style={{
          textAlign: "center",
          width: "100vw",
          position: "relative",
        }}
      >
        <img
          src={silkieChicken}
          style={{ width: "300px", mixBlendMode: "screen" }}
        />
      </div>
      <p
        className="s1"
        style={{
          textAlign: "center",
          fontSize: "22px",
        }}
      >
        Silkie Chicken Package
      </p>
      <div
        style={{
          display: "flex",
          width: "min(500px, 80vw)",
          position: "relative",
          flexDirection: "column",
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <table style={{ borderCollapse: "collapse" }} cellSpacing={0}>
          <tbody>
            <tr style={{ height: "24pt" }}>
              <td
                style={{
                  width: "135pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "137pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Fridays and Weekends
                </p>
              </td>
              <td
                style={{
                  width: "81pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Weekdays
                </p>
              </td>
            </tr>
            <tr style={{ height: "23pt" }}>
              <td
                style={{
                  width: "135pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Event + overnight stay in 2024
                </p>
              </td>
              <td
                style={{
                  width: "137pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  $5,500
                </p>
              </td>
              <td
                style={{
                  width: "81pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  $3,000
                </p>
              </td>
            </tr>
            <tr style={{ height: "23pt" }}>
              <td
                style={{
                  width: "135pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Event + overnight stay in 2025
                </p>
              </td>
              <td
                style={{
                  width: "137pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  $7,800
                </p>
              </td>
              <td
                style={{
                  width: "81pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  $4,200
                </p>
              </td>
            </tr>
            <tr style={{ height: "23pt" }}>
              <td
                style={{
                  width: "135pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Event + overnight stay in 2026
                </p>
              </td>
              <td
                style={{
                  width: "137pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  $8,500
                </p>
              </td>
              <td
                style={{
                  width: "81pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  $4,700
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        style={{
          display: "flex",
          width: "min(500px, 80vw)",
          position: "relative",
          flexDirection: "column",
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "11pt",
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            Includes everything in the Blue Heron Package
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            Tablecloths, plates, silverware, and place settings for up to 220
            guests
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            Glass vase centerpieces for tables (ﬂowers not included)
          </p>
        </li>
        <hr style={{ width: "540px", marginLeft: "-25px" }} />
      </div>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <span />
      </p>
      <p />
      <div
        style={{
          textAlign: "center",
          width: "100vw",
          position: "relative",
        }}
      >
        <img src={peacock} style={{ width: "300px", mixBlendMode: "screen" }} />
      </div>
      <p
        className="s1"
        style={{
          textAlign: "center",
          fontSize: "22px",
        }}
      >
        Peacock Package
      </p>
      <div
        style={{
          display: "flex",
          width: "min(500px, 80vw)",
          position: "relative",
          flexDirection: "column",
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <table style={{ borderCollapse: "collapse" }} cellSpacing={0}>
          <tbody>
            <tr style={{ height: "24pt" }}>
              <td
                style={{
                  width: "135pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "136pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Fridays and Weekends
                </p>
              </td>
              <td
                style={{
                  width: "82pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Weekdays
                </p>
              </td>
            </tr>
            <tr style={{ height: "24pt" }}>
              <td
                style={{
                  width: "135pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Event + overnight stay in 2024
                </p>
              </td>
              <td
                style={{
                  width: "136pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  $6,300
                </p>
              </td>
              <td
                style={{
                  width: "82pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  $3,200
                </p>
              </td>
            </tr>
            <tr style={{ height: "24pt" }}>
              <td
                style={{
                  width: "135pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Event + overnight stay in 2025
                </p>
              </td>
              <td
                style={{
                  width: "136pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  $8,600
                </p>
              </td>
              <td
                style={{
                  width: "82pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  $4,900
                </p>
              </td>
            </tr>
            <tr style={{ height: "23pt" }}>
              <td
                style={{
                  width: "135pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Event + overnight stay in 2026
                </p>
              </td>
              <td
                style={{
                  width: "137pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  $9,100
                </p>
              </td>
              <td
                style={{
                  width: "81pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s2"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  $5,400
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        style={{
          display: "flex",
          width: "min(500px, 80vw)",
          position: "relative",
          flexDirection: "column",
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "11pt",
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            Includes everything in the Silkie Chicken Package
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            Venue coordinator for setup and takedown/cleanup
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            Custom cake
          </p>
        </li>
        <hr style={{ width: "540px", marginLeft: "-25px" }} />
      </div>
      <br />
      <br />
      <br />
      <div
        style={{
          textAlign: "center",
          width: "100vw",
          position: "relative",
        }}
      >
        <img src={quail} style={{ width: "300px", mixBlendMode: "screen" }} />
      </div>
      <div
        style={{
          display: "flex",
          width: "min(500px, 80vw)",
          position: "relative",
          flexDirection: "column",
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <p
          style={{
            textAlign: "center",
            fontSize: "22px",
          }}
        >
          <p>Quail Catering Package</p>
        </p>
        <li data-list-text="➢">
          <p
            style={{
              paddingLeft: "18pt",
              lineHeight: "14pt",
              textAlign: "left",
              marginTop: "-10px",
            }}
          >
            Price: $500 deposit (fully refundable upon event cancellation)
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "18pt",
              textAlign: "left",
            }}
          >
            Exquisite cuisine provided through{" "}
            <a
              style={{ color: "#57d2ff" }}
              href="https://pacificnorthwestcatering.com"
              target="_blank"
            >
              Pacific Northwest Catering
            </a>
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "18pt",
              textAlign: "left",
            }}
          >
            Receive 5% off on the food prices listed in the menus by filling out
            the form at{" "}
            <a
              style={{ color: "#57d2ff" }}
              href="https://www.pacificnorthwestcatering.com/request-form"
              target="_blank"
            >
              https://www.pacificnorthwestcatering.com/request-form
            </a>{" "}
            and booking with Pretty Bird. Tablecloths and place settings will be
            provided when purchased in conjunction with an Osprey, Silkie
            Chicken, or Peacock package. For parties booking a Hummingbird or
            Blue Heron package, tablecloths are available at a rate of $15 per
            tablecloth.
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "18pt",
              textAlign: "left",
            }}
          >
            Pricing to be adjusted based on menu items selected and number of
            guests. Your discount is secured by booking a package through Pretty
            Bird
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "18pt",
              textAlign: "left",
            }}
          >
            Menu selection and final head count are due one month prior to your
            event, and final price will be adjusted at that time
          </p>
        </li>
        <br />
      </div>
      <hr style={{ width: "540px", margin: "auto" }} />
      <br />
      <br />
      <br />
      <div
        style={{
          textAlign: "center",
          width: "100vw",
          position: "relative",
        }}
      >
        <img
          src={whiteFlowers}
          style={{ width: "300px", mixBlendMode: "screen" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          width: "min(500px, 80vw)",
          position: "relative",
          flexDirection: "column",
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <p
          style={{
            textAlign: "center",
            fontSize: "22px",
          }}
        >
          <p>Floral Packages</p>
        </p>
        <li data-list-text="➢">
          <p
            style={{
              paddingLeft: "18pt",
              lineHeight: "14pt",
              textAlign: "left",
              marginTop: "-10px",
            }}
          >
            All floral packages are provided by{" "}
            <a
              href="https://www.lulusartistryflorist.company"
              target="_blank"
              style={{ color: "#57d2ff" }}
            >
              Lulu's Artistry Florist Company
            </a>
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "18pt",
              textAlign: "left",
            }}
          >
            All packages include Roses, Hydrangeas, premium greenery, and
            seasonal flowers and have customizable color schemes upon request
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "18pt",
              textAlign: "left",
            }}
          >
            Disclaimer: Our packages are uniquely designed and only customizable
            in terms of color palette. For smaller floral requests, our à la
            carte services are available. To order à la carte services, please
            access the form on our website at{" "}
            <a
              href="https://www.lulusartistryflorist.company/contact-us"
              target="_blank"
              style={{ color: "#57d2ff" }}
            >
              https://www.lulusartistryflorist.company/contact-us
            </a>
          </p>
        </li>
        <br />
      </div>
      <hr style={{ width: "540px", margin: "auto" }} />

      <br />
      <br />
      <br />
      <div
        style={{
          textAlign: "center",
          width: "100vw",
          position: "relative",
        }}
      >
        <img src={finch} style={{ width: "300px", mixBlendMode: "screen" }} />
      </div>
      <div
        style={{
          display: "flex",
          width: "min(500px, 80vw)",
          position: "relative",
          flexDirection: "column",
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <p
          style={{
            textAlign: "center",
            fontSize: "22px",
          }}
        >
          <p>Finch Package</p>
        </p>
        <li data-list-text="➢">
          <p
            style={{
              paddingLeft: "18pt",
              lineHeight: "14pt",
              textAlign: "left",
              marginTop: "-10px",
            }}
          >
            Price: $2,700
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "18pt",
              textAlign: "left",
            }}
          >
            Bud vases for up to 22 tables
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "18pt",
              textAlign: "left",
            }}
          >
            One specially crafted bridal bouquet
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "18pt",
              textAlign: "left",
            }}
          >
            Up to 10 boutonnières
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "18pt",
              textAlign: "left",
            }}
          >
            Up to 7 bridesmaids bouquets
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "18pt",
              textAlign: "left",
            }}
          >
            Wedding arbor arrangement for center of arbor
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "18pt",
              textAlign: "left",
            }}
          >
            Small Sweetheart table arrangement
          </p>
        </li>
        <br />
      </div>
      <hr style={{ width: "540px", margin: "auto" }} />

      <br />
      <br />
      <br />
      <div
        style={{
          textAlign: "center",
          width: "100vw",
          position: "relative",
        }}
      >
        <img src={swan} style={{ width: "300px", mixBlendMode: "screen" }} />
      </div>
      <div
        style={{
          display: "flex",
          width: "min(500px, 80vw)",
          position: "relative",
          flexDirection: "column",
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <p
          style={{
            textAlign: "center",
            fontSize: "22px",
          }}
        >
          <p>Swan Package</p>
        </p>
        <li data-list-text="➢">
          <p
            style={{
              paddingLeft: "18pt",
              lineHeight: "14pt",
              textAlign: "left",
              marginTop: "-10px",
            }}
          >
            Price: $4600
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "18pt",
              textAlign: "left",
            }}
          >
            Includes everything in the finch package
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "18pt",
              textAlign: "left",
            }}
          >
            Bud Vases for up to 15 additional tables
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "18pt",
              textAlign: "left",
            }}
          >
            Two arrangements for front of wedding aisle
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "18pt",
              textAlign: "left",
            }}
          >
            Fresh rose petals for flower girls
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "18pt",
              textAlign: "left",
            }}
          >
            Two floral corsages
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "18pt",
              textAlign: "left",
            }}
          >
            Guestbook Centerpiece
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "18pt",
              textAlign: "left",
            }}
          >
            Bar and beverage arrangement
          </p>
        </li>
        <br />
      </div>
      <hr style={{ width: "540px", margin: "auto" }} />

      <br />
      <br />
      <br />
      <div
        style={{
          textAlign: "center",
          width: "100vw",
          position: "relative",
        }}
      >
        <img
          src={foodTable}
          style={{ width: "300px", mixBlendMode: "screen" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          width: "min(500px, 80vw)",
          position: "relative",
          flexDirection: "column",
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <p
          style={{
            textAlign: "center",
            fontSize: "22px",
          }}
        >
          <p>Items to add to your package</p>
        </p>
        <li data-list-text="➢">
          <p
            style={{
              paddingLeft: "18pt",
              // textIndent: "-18pt",
              lineHeight: "14pt",
              textAlign: "left",
              marginTop: "-10px",
            }}
          >
            Event insurance <b>(required for every event)</b>: Please follow{" "}
            <a
              href="https://secure.rightsignature.com/signers/01502dc4-735e-4646-b0d2-3898274dedf5/sign?access_token=3_vgFyUQC-6E4XdN89ys"
              target="_blank"
              // make the link light blue
              style={{ color: "#57d2ff" }}
            >
              this link
            </a>
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            Tablecloth rental: $15 each
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "41pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            Plate/silverware/napkin rental: $5 per place setting
          </p>
        </li>
        <li data-list-text="➢">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "18pt",
              textAlign: "left",
            }}
          >
            Custom cake: ~$650 for a cake that feeds up to 130, ~$900 for a cake
            that feeds up to 200. Prices may vary based on flavor/type of cake,
            and smaller cakes are available with custom pricing
          </p>
        </li>
        <br />
      </div>
      <br />
      <br />
      <br />
    </ul>
  </div>
);

export default ConvertedPackages;
