function extractDateComponents(dateString: string) {
  // Split the date string into an array [year, month, day]
  const parts = dateString.split("-");

  // Extract year, month, and day as integers
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const day = parseInt(parts[2], 10);

  // Return an object containing the year, month, and day
  return { year, month: month - 1, day };
}

const createDate = (yearMonthDay: string) => {
  const { year, month, day } = extractDateComponents(yearMonthDay);
  const result = new Date(year, month, day);
  // result.setHours(result.getHours() + 8); //add 7 hours for PST time zone
  return result;
};

export const getDatesInRange = (
  startDate: string,
  endDate: string
): ConvertedBooking => {
  const convertedStart = createDate(startDate);
  const endingDate = createDate(endDate);
  return { start: convertedStart, end: endingDate, title: "Booked" };
};

interface GoogleBooking {
  start: {
    date: string;
  };
  end: {
    date: string;
  };
}

export interface ConvertedBooking {
  start: Date;
  end: Date;
  title: string;
}

export const getBookingsList = (bookings: GoogleBooking[]) => {
  const result: ConvertedBooking[] = [];
  bookings.forEach((booking) => {
    result.push(getDatesInRange(booking.start.date, booking.end.date));
  });
  result.push({
    start: createDate("2023-12-02"), //resolves to december 1st
    end: createDate("2024-03-15"),
    title: "Closed for winter",
  });
  result.push({
    start: createDate("2024-12-02"),
    end: createDate("2025-03-15"),
    title: "Closed for winter",
  });
  result.push({
    start: createDate("2025-12-02"),
    end: createDate("2026-03-15"),
    title: "Closed for winter",
  });
  return result;
};
