import React from "react";
import classes from "./Vendors.module.css";
import headerClasses from "../Home/Home.module.css";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { BlueHeron, maryluFlorist } from "../../Assets";

interface Row {
  image: string;
  vendor: string;
  service: string;
  website: string;
  customLink?: boolean;
}

const Vendors = () => {
  const createRow = (
    image: string,
    vendor: string,
    service: string,
    website: string,
    customLink?: boolean
  ): Row => ({
    image,
    vendor,
    service,
    website,
    customLink,
  });
  const rows: Row[] = [
    createRow(
      maryluFlorist,
      "Lulu’s Artistry Florist Company",
      "Our top partner for floral designs!",
      "https://www.lulusartistryflorist.company"
    ),
    createRow(
      "",
      "Have Your Cake! Custom Celebration Cakes by Betsy Robertson",
      "Wedding cakes",
      (
        <>
          <p>
            Email{" "}
            <a
              href="mailto:betsy-robertson@outlook.com"
              target="_blank"
              rel="noreferrer"
            >
              betsy-robertson@outlook.com
            </a>
          </p>{" "}
          or call or text 360-721-8550
        </>
      ) as any,
      true
    ),
    createRow(
      "https://le-cdn.hibuwebsites.com/d92775da5f424394a053cd8a5037c621/dms3rep/multi/opt/pacific-northwest-catering-logo-492w.jpg",
      "Pacific Northwest Catering Company",
      "Catering",
      "https://www.pacificnorthwestcatering.com"
    ),

    createRow(
      BlueHeron,
      "Blue Heron Event Rentals",
      "Marquee letters, dance floors, and lounge area rentals",
      "https://www.blueheroneventrentals.com/"
    ),
    createRow(
      "https://images.squarespace-cdn.com/content/v1/63d8528b9ef03729e22f2151/c41cae38-89c1-4b45-a7e7-020cff0abaac/HERO-IMAGE-WHITE.png?format=2500w",
      "Fearless Florals",
      "Floral designs",
      "https://www.fearlessfloralswa.com/"
    ),
    createRow(
      "https://images.squarespace-cdn.com/content/v1/59966b72f14aa122b4d6ace9/1589318365211-HBZXSIJTPWZFF2HZL2GB/PMP+LIGHT+AND+BLACK+LOGO.png?format=1000w",
      "Pacific Mist Photography",
      "Photography",
      "https://www.pacificmistphotography.com/"
    ),
    createRow(
      "",
      "Sweet Buffet Lady Rentals",
      "Rental decor and sweet buffets",
      "https://www.sweetbuffetlady.com/"
    ),
    createRow(
      "https://img1.wsimg.com/isteam/ip/34c5325a-5d1c-4667-9462-222d6a4c00e3/aha-party-co-logo-color-transparent-5.2023.png/:/rs=w:495,h:194,cg:true,m/cr=w:495,h:194/qt=q:95",
      "Aha Party Co.",
      "Ice cream, pretzels, and photo booths",
      "https://ahapartyco.com/"
    ),
    createRow(
      "https://www.thebackyardbrunchcompany.com/uploads/b/09eaec64cbb47d7414dd976bfde08140b7dd61a6147d0c2fec6bfcc7bd2691b5/1_1630903719.png?width=400&optimize=medium",
      "the backyard brunch",
      "picnic setup, tabletop styling",
      "https://www.thebackyardbrunchcompany.com/"
    ),
    createRow(
      "https://www.danikcookies.com/uploads/b/c85a7bf24f141538b45aeb9ca44f52efff78e18f5e66fc3e246f114e6c43fadc/Screen%20Shot%202022-05-23%20at%2010.02.47%20AM_1653345093.png?width=400&optimize=medium",
      "Dani K Cookies",
      "Cookies",
      "https://www.danikcookies.com/"
    ),
    createRow(
      "https://images.squarespace-cdn.com/content/v1/5dc74567e8c8347aab4fc1a3/1574553145423-2RD4F6PVHE6YZVXWGRNX/5.png?format=1500w",
      "SNO BRO KOLLECTIV",
      "DJ",
      (
        <>
          <a
            href="mailto:ryan@snobrokollectiv.com"
            target="_blank"
            rel="noreferrer"
          >
            ryan@snobrokollectiv.com
          </a>
          <p></p>
          <a href="https://www.snobrokollectiv.com/">
            https://www.snobrokollectiv.com/
          </a>
        </>
      ) as any,
      true
    ),
    createRow(
      "https://static.wixstatic.com/media/feea88_a6774abd22df457389cee36f1b4d89aa~mv2.png/v1/fill/w_269,h_211,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Pizza%20BG%20Logo.png",
      "Gusto Wood Fired Pizza",
      "Catering",
      "https://www.gustowoodfiredpizza.com"
    ),
    createRow(
      "https://www.inflatenorthwest.com/wp-content/uploads/2020/02/Inflate-NW_Burst-768x314.png",
      "Inflate Northwest",
      "Bounce house rentals",
      "https://www.inflatenorthwest.com/"
    ),
    createRow(
      "https://img1.wsimg.com/isteam/ip/480f3fb9-d99e-43e9-aac2-fdc27a9e64ec/logo.png/:/rs=w:206,h:206,cg:true,m/cr=w:206,h:206/qt=q:95",
      "Moon Rae Cookies",
      "Cookies",
      "https://moonraecookies.com/"
    ),
    createRow(
      "https://static.wixstatic.com/media/f8cc46_d7f4ebdc0a9d44b7b2ead0602e0ef8a5~mv2.png/v1/fill/w_900,h_822,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/Photobooth.png",
      "The Photo Booth Company",
      "Photography",
      "https://www.photoboothco-sea.com/"
    ),
    createRow(
      "",
      "Madison Ballard",
      "Event planning/coordination",
      "https://linktr.ee/ballardeventplanning"
    ),
    createRow(
      "https://alisonmayphotography.com/wp-content/uploads/2022/12/AM-LOGO-WHITE-400x400.png",
      "Alison May Photography",
      "Photography",
      "https://alisonmayphotography.com/"
    ),
    createRow(
      "https://static.wixstatic.com/media/61b61e_4189276fe7a44bc289aee00e453ecb46~mv2.png/v1/crop/x_357,y_402,w_1356,h_1224/fill/w_93,h_84,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Valen%20Vital_Logo_Transparent%20BG_Icon.png",
      "Valen Vital Health",
      "Weight loss therapy, dieting, and exercise",
      "https://www.valenvitalhealth.com/"
    ),
    createRow(
      "https://www.mochamotion.org/wp-content/uploads/2020/10/center-removebg-preview.png",
      "Mocha Motion - Espresso Catering",
      "Coffee catering",
      "https://www.mochamotion.org/"
    ),
    createRow(
      "https://cache.marriott.com/content/dam/marriott-digital/de/global-property-shared/en_us/logo/assets/de-delta-hotels-logo-41030.png",
      "Delta Hotels Seattle Everett",
      "Lodging",
      "https://www.marriott.com/en-us/hotels/seavt-delta-hotels-seattle-everett/overview/"
    ),
    createRow(
      "https://images.squarespace-cdn.com/content/v1/65a5d4975bdab40fa3028db8/f650233d-2fca-456d-a128-0c691bf84bbe/bene-rosas-type-01.png?format=1500w",
      "Bene Rosa's Bar Services",
      "Drink catering",
      "https://www.benerosasllc.com/"
    ),
    createRow(
      "",
      "Meraki Party Rentals",
      "Backdrop setup and rentals",
      "https://www.instagram.com/merakipartyrentals/"
    ),
    createRow(
      "",
      "The Rustic Pour",
      "Mobile bartending",
      "http://therusticpour.com/"
    ),
    createRow(
      "",
      "Turtle's Occasions",
      "Event planning",
      "https://www.turtlesoccasions.com/"
    ),
    createRow(
      "",
      "That Event Mom",
      "Event planning and styling",
      "https://thateventmom.com/"
    ),
    createRow(
      "",
      "Orka Designs",
      "Rentals and custom designs",
      "https://www.instagram.com/orkadesigns/"
    ),
    createRow(
      "",
      "Para Ti Designs",
      "Graphic and hand-drawn designs",
      "https://www.instagram.com/parati_designs/"
    ),
    createRow(
      "",
      "Beyond the Knots",
      "Macramé art",
      "https://www.instagram.com/_beyondtheknots_/"
    ),
    createRow(
      "",
      "Esty's Handworks and Design",
      "Floral designs and event decor",
      "https://www.instagram.com/estyshandworksandesign/"
    ),
    createRow(
      "",
      "Love Launch",
      "Professional wedding & event planning services",
      "https://lovelaunch.info/"
    ),
    createRow(
      "",
      "Music De-Lite DJ Service",
      "DJ",
      (
        <>
          <a
            href="mailto:musicdelite@comcast.net"
            target="_blank"
            rel="noreferrer"
          >
            musicdelite@comcast.net
          </a>
          <p>or call or text 360-456-1578</p>
        </>
      ) as any,
      true
    ),
    createRow(
      "",
      "Reverend Sunny Radford",
      "Officiant and live music MC",
      "https://linktr.ee/lipservus"
    ),
  ];
  const tableHeaderStyle = { color: "white", fontWeight: "700" };
  return (
    <div>
      <div className={classes.headerBackdrop}>
        <div className={headerClasses.header}>
          <h1>PREFERRED VENDORS</h1>
        </div>
      </div>
      <p className={classes.vendorDescription}>
        We are proud to partner with local vendors for our weddings and events!
        Please refer to the table below to find vendors that support our venue.
      </p>
      <div className={classes.vendorTable}>
        <TableContainer
          component={Paper}
          sx={{ backgroundColor: "rgba(255, 255, 255, 0.15)" }}
        >
          <Table aria-label="vendor table">
            <TableHead>
              <TableRow
                sx={{ borderBottom: "2px solid rgba(255,255,255,0.3)" }}
              >
                <TableCell sx={tableHeaderStyle}>Vendor</TableCell>
                <TableCell sx={tableHeaderStyle}>Services Provided</TableCell>
                <TableCell sx={tableHeaderStyle}>Website</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(({ image, vendor, service, website, customLink }) => (
                <TableRow
                  sx={{ borderBottom: "2px solid rgba(255,255,255,0.3)" }}
                >
                  <TableCell sx={{ color: "white" }} width="33%">
                    <div className={classes.vendorCell}>
                      {image && <img src={image} alt={vendor} />}
                      {vendor}
                    </div>
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", minWidth: "100px" }}
                    width="50%"
                  >
                    {service}
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>
                    {customLink ? (
                      website
                    ) : (
                      <a href={website} target="_blank" rel="noreferrer">
                        {website}
                      </a>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Vendors;
