import React from "react";
import { Logo } from "../../Assets";
import classes from "./Home.module.css";
import "./ReactPlayer.css";
import { LabeledVenue } from "../../Assets";

const Home = () => {
  return (
    <div>
      <div className={classes.headerBackdrop}>
        <div className={classes.header}>
          <img src={Logo} alt="" />
          <h1>PRETTY BIRD WEDDINGS AND EVENTS</h1>
        </div>
      </div>
      <h2 className={classes.homeSubheader}>
        Discover the magic of Pretty Bird — Book your wedding date before it's
        gone!
      </h2>
      <div className={classes.homeBlurb}>
        <img
          src={LabeledVenue}
          alt="Labeled Venue"
          className={classes.labeledVenue}
        />
        <div className={classes.homeBlurbText}>
          <p>
            Welcome to Pretty Bird Weddings and Events, a newly launched venue.
            Come discover an exquisite location at unbeatable prices nestled on
            30 acres of pristine land. A tour is a must as we have many upgrades
            and improvements not yet showcased on this website. Our enchanting
            estate offers a picturesque setting for your special day, surrounded
            by lush evergreen trees, a stunning view of the majestic Mt.
            Pilchuck, and pretty birds of all kinds.
          </p>
          <p>
            Experience the versatile beauty of our venue, where you have the
            freedom to choose between hosting your ceremony in a modern barn or
            amidst the breathtaking outdoors. Our striking barn harmoniously
            combines contemporary elegance with the rustic charm of nature,
            boasting a sleek design and ample seating.
          </p>
          <p>
            As you wander through our expansive grounds, you'll encounter a
            beautiful variety of creatures. Graceful horses roam in the meadows,
            while an abundance of vibrant birds, including bald eagles, hawks,
            chickens, turkeys, and peacocks fill the air with their harmonious
            melodies.
          </p>
          <p>
            For your comfort and convenience, our venue features a spacious 5
            bedroom, 3 bathroom house with a den. This delightful retreat offers
            modern amenities and a serene ambiance, providing you and your
            wedding party with a relaxing space to prepare and unwind before
            your big day.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
