import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import classes from "./NavItem.module.css";

interface Props {
  scrolled: boolean;
  link: string;
  hover?: () => void;
  children: ReactNode;
}

const NavItem = (props: Props) => (
  <li
    className={
      props.scrolled
        ? classes.NavItem + " " + classes.scrolled
        : classes.NavItem
    }
  >
    <NavLink
      to={props.link}
      className={({ isActive }) => (isActive ? classes.active : "")}
      onMouseEnter={props.hover}
    >
      <div className={classes.linkWrapper}>
        <p>{props.children}</p>
      </div>
    </NavLink>
  </li>
);

export default NavItem;
