import React, { useEffect, useState } from "react";
import { ConvertedBooking, getBookingsList } from "./utils";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import classes from "./Contact.module.css";

const localizer = momentLocalizer(moment);

const PublicCalendar = () => {
  const [bookings, setBookings] = useState<ConvertedBooking[]>([]);
  useEffect(() => {
    const loadCalendar = () => {
      var calendarId =
        "c_bd6b5facc9d857de8dc96439211909cde1502e667b66752092046ea82dc8f2f7@group.calendar.google.com";
      var apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
      var userTimeZone = "America/Los_Angeles";
      //@ts-ignore
      gapi.client
        .init({
          apiKey: apiKey,
          discoveryDocs: [
            "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
          ],
        })
        .then(() => {
          //@ts-ignore
          return gapi.client.calendar.events.list({
            calendarId,
            timeZone: userTimeZone,
            singleEvents: true,
            timeMin: new Date().toISOString(),
            orderBy: "startTime",
          });
        })
        .then(
          //@ts-ignore
          (response) => {
            if (response.result.items) {
              setBookings(getBookingsList(response.result.items));
            }
          }
        );
    };

    //@ts-ignore
    gapi.load("client", loadCalendar);
  }, []);
  return (
    <div className={classes.calendarWrapper}>
      <div className={classes.calendar}>
        <Calendar
          localizer={localizer}
          events={bookings}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          views={["month"]}
          eventPropGetter={() => {
            return { style: { backgroundColor: "#d1e6d4", color: "black" } };
          }}
        />
      </div>
    </div>
  );
};

export default PublicCalendar;
