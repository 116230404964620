import React from "react";
import classes from "./Packages.module.css";
import headerClasses from "../Home/Home.module.css";
import ConvertedPackages from "./convertedPackages";

const Packages = () => {
  return (
    <div>
      <div className={classes.headerBackdrop}>
        <div className={headerClasses.header}>
          <h1>PACKAGES</h1>
        </div>
      </div>
      <ConvertedPackages />
    </div>
  );
};

export default Packages;
