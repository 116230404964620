import React from "react";
import classes from "./Contact.module.css";
import headerClasses from "../Home/Home.module.css";
import PublicCalendar from "./PublicCalendar";

const Contact = () => {
  return (
    <div>
      <div className={classes.headerBackdrop}>
        <div className={headerClasses.header}>
          <h1>Book a Wedding</h1>
        </div>
      </div>
      <div className={classes.contact}>
        <p className={classes.contactText}>
          If you're interested in booking a wedding or event, please refer to
          the calendar below to verify that your dates are available. You can
          contact us by phone at 206-399-0681 or by email at{" "}
          <a href="mailto:booking@prettybirdwedding.com?subject=Wedding/Event Inquiry&body=Name: %0D%0AContact Email: %0D%0APhone number: %0D%0ADates desired: %0D%0APackage: %0D%0ANumber of Guests: %0D%0AAny Additional Items or Questions: ">
            booking@prettybirdwedding.com
          </a>
          . Please include your full name, email, phone number, dates you're
          looking to book,{" "}
          <a href="/packages" target="_blank">
            packages
          </a>{" "}
          you're interested in, and approximate number of guests.
        </p>
      </div>
      <PublicCalendar />
    </div>
  );
};

export default Contact;
